<template>
  <div class="content">
    <div class="container">
      <div class="row">
        <div class="col-sm-12" style="text-align: justify">
          <div class="headline style-3">
            <h5>Biuro Storno</h5>
            <h2>Przydatne Linki</h2>
          </div>

          <table class="c-links" style="width: 100%">
            <tbody>
              <tr
                v-for="(link, index) in links"
                :key="index"
              >
                <td class="style2">
                  <a :href="link[0]" target="_blank">
                    {{ link[1] }}
                  </a>
                </td>
                <td class="style2">
                  {{ link[2] }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  head: {
    title: 'Linki - Biuro rachunkowe Poznań "STORNO"'
  },
  data () {
    return {
      links: [
        ['http://ec.europa.eu/taxation_customs/vies/', 'ec.europa.eu/taxation_customs', 'strona umożliwiająca sprawdzenie NIP UE kontrahenta unijnego'],
        ['https://ppuslugi.mf.gov.pl/?link=VAT', 'ppuslugi.mf.gov.pl/?link=VAT', 'Sprawdzanie poprawności numeru NIP  i tego czy podmiot jest zarejestrowany jako podatnik VAT czynny'],
        ['http://urzadskarbowy24.pl/', 'urzadskarbowy24.pl', 'wyszukiwarka urzędów skarbowych'],
        ['http://bazy.hoga.pl/urzedy.asp', 'bazy.hoga.pl/urzedy.asp', 'wyszukiwarka urzędów skarbowych na podstawie adresu'],
        ['http://www.stat.gov.pl/regon/', 'stat.gov.pl/regon/', 'wyszukiwarka firm na podstawie nr REGON lub NIP (baza danych REGON)'],
        ['https://ems.ms.gov.pl/krs/wyszukiwaniepodmiotu', 'ems.ms.gov.pl/krs/wyszukiwaniepodmiotu', 'Krajowy Rejestr Sądowy (KRS)'],
        ['https://prod.ceidg.gov.pl/CEIDG/ceidg.public.ui/Search.aspx', 'prod.ceidg.gov.pl', 'Baza przedsiębiorców w CEIDG'],
        ['http://isap.sejm.gov.pl/', 'isap.sejm.gov.pl/', 'internetowy system aktów prawnych'],
        ['http://stat.gov.pl/Klasyfikacje/', 'stat.gov.pl/Klasyfikacje/', 'klasyfikacje statystyczne (PKD, PKWiU, PKOB, KŚT, KZS)'],
        ['http://www.kalkulatory.gofin.pl/', 'kalkulatory.gofin.pl', 'kalkulatory'],
        ['http://www.wskazniki.gofin.pl/', 'wskazniki.gofin.pl/', 'wskaźniki, stawki'],
        ['http://www.mf.gov.pl', 'mf.gov.pl', 'strona Ministerstwa Finansów'],
        ['http://www.zus.pl', 'zus.pl', 'strona Zakładu Ubezpieczeń Społecznych'],
        ['http://www.eskladka.pl', 'eskladka.pl', 'wyszukiwarka indywidualnego numeru rachunku składkowego w ZUS'],
        ['http://www.krus.gov.pl/', 'krus.gov.pl', 'strona Kasy Rolniczego Ubezpieczenia Społecznego'],
        ['http://www.stat.gov.pl', 'stat.gov.pl', 'strona Głównego Urzędu Statystycznego'],
        ['http://www.pip.gov.pl', 'pip.gov.pl', 'strona Państwowej Inspekcji Pracy'],
        ['http://www.sejm.gov.pl', 'sejm.gov.pl', 'strona Sejmu Rzeczypospolitej Polskiej'],
        ['http://www.nbp.pl', 'nbp.pl', 'strona Narodowego Banku Polskiego'],
        ['http://www.poznan.apodatkowa.gov.pl', 'poznan.apodatkowa.gov.pl', 'strona Poznańskiej Izby Skarbowej'],
        ['http://www.gazetaprawna.pl', 'gazetaprawna.pl', 'dziennik o gospodarce i prawie'],
        ['http://www.rp.pl', 'rp.pl', 'internetowe wydanie gazety "Rzeczpospolita"'],
        ['http://www.mr.gov.pl/', 'mr.gov.pl', 'strona Ministerstwa Rozwoju'],
        ['http://www.mpips.gov.pl/', 'mpips.gov.pl', 'strona Ministerstwa Rodziny, Pracy i Polityki Społecznej'],
        ['http://www.mz.gov.pl/', 'mz.gov.pl', 'strona Ministerstwa Zdrowia'],
        ['http://www.ms.gov.pl/', 'ms.gov.pl', 'strona Ministerstwa Sprawiedliwości'],
        ['http://www.pfron.org.pl/', 'pfron.org.pl', 'strona Państwowego Funduszu Rehabilitacji Osób Niepełnosprawnych'],
        ['http://www.sod.pfron.org.pl', 'sod.pfron.org.pl', 'system obsługi dofinansowań i refundacji PFRON'],
        ['https://pracodawca.e-pfron.pl/login', 'pracodawca.e-pfron.pl', 'system e-PFRON2 dla pracodawców zobowiązanych do wpłat na rzecz PFRON'],
        ['https://sudop.uokik.gov.pl/', 'sudop.uokik.gov.pl', 'system udostępniania danych o pomocy publicznej'],
        ['https://krdp.pl', 'krdp.pl', 'strona Krajowej Izby Doradców Podatkowych'],
        ['https://prod.ceidg.gov.pl/', 'prod.ceidg.gov.pl', 'Centralna Ewidencja i Informacja o Działalności Gospodarczej (CEIDG)'],
        ['https://pz.gov.pl/', 'pz.gov.pl', ' profil zaufany'],
        ['https://epuap.gov.pl/', 'epuap.gov.pl', 'ePUAP (elektroniczna Platforma Usług Administracji'],
        ['https://prod.ceidg.gov.pl/CEIDG/CEIDG.Public.UI/DecisionAdditionalParameters.aspx?type=1', 'prod.ceidg.gov.pl', 'Założenie działalności gospodarczej (wypełnienie i złożenie wniosku)'],
        ['http://zielonalinia.gov.pl', 'zielonalinia.gov.pl', 'Centrum informacyjne urzędów pracy'],
        ['http://www.pup.poznan.pl', 'pup.poznan.pl', 'strona Powiatowego Urzędu Pracy w Poznaniu'],
        ['http://www.pcs-poznan.pl/', 'pcs-poznan.pl', 'strona Poznańskiego Centrum Świadczeń'],
        ['http://bip.poznan.pl', 'bip.poznan.pl', 'Urząd Miasta Poznania – Biuletyn Informacji Publicznej']
      ]
    }
  }
}
</script>
